const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: '85e80adc3795147182c681ce7e69cbb9',
        secretKey: 'debd7036cbd4a88c1dd0f5cb83c0e832',
        publicKey:
          '-----BEGIN PUBLIC KEY-----' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAssAOqkyTje' +
          'H2A5OJBQducDPKC/J06hSraWQDvVtlVCPAqa8Jqnsv1x5Kb4i69WM7' +
          'GAis/EsjPYKEyxy6JEcCiZPqDpK2Poi8CwOvknD+reSQ9aCbqWkGPi' +
          'eRc8mYq8LgTzZtp0B2iiv4IcYHCWLdj/5lUIMmLe7fdMP9wo5+W3Xl' +
          'pBAcHyH+VJ3xzq/AaSR1JM4XEvQSLoMSnI2SW9Tusl3feUEB/iJVpu' +
          'oZ7lm9zWwcHmrHhCCuXuxdAoz6tG4jFBa2qK/OaI/EYzftBBsjdaH0' +
          'Od3IZUHfSJ2GHhzo1CEXONs8PpM1m47yaNVHhhMS/TZRR1url4/pR0' +
          'DwA7xFAwIDAQAB' +
          '-----END PUBLIC KEY-----',
      }
    : {
        appId: 'abf227432f9935a0a0dad51c5338a825',
        secretKey: '36f752f5c3733f83e3b0484c340bfd90',
        publicKey:
          '-----BEGIN PUBLIC KEY-----' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArnOzprxuryHHITI9oAL4' +
          '7FERvLyX5wPbE5Qy0q4vwnlj53zvAWXFfJ56/HJ9uoUMMu3Nz20SKt2vkXZ+1bcT' +
          'u3gUVnLZt+8c1Qmt2x5UFSj+Ip9Muc0CFr16ZYx9FMitabZO3dbe0qUH1+1x04Pf' +
          'pHDw2yCF4KpLTDfMmPBbmbs85RG1X6a0TrTpaFmvM+kl8Xr/yf3pamnGEaDNIBnL' +
          'WfR1er308DBXETOef3enx2/derbLJiQsWNQ3xWZZmhcUzRA6CuES/yZPndsmSIJp' +
          'CSxqPKqhibfVFacHYfXJ2PzDzVXM9SBp5lLZLkRI5L0rLq8CPQ8lULFDnvV0KmF9' +
          'WQIDAQAB' +
          '-----END PUBLIC KEY-----',
      };

const envConfig = (() => {
  // mock, dev, test1, reg
  if (['mock', 'dev', 'reg'].indexOf(REACT_APP_ENV) >= 0) {
    let env = 'env3';
    switch (REACT_APP_ENV) {
      case 'reg':
        env = 'reg';
        break;
      case 'dev':
      case 'mock':
      default:
        env = 'env3';
        break;
    }
    // const env = REACT_APP_ENV === 'test1' ? 'env1' : 'env3';
    return {
      iconPreUrl: `https://fast-escrow-h5-${env}.test.lenteradana.co.id`,
    };
  }

  if (REACT_APP_ENV === 'test1') {
    // staging
    return {
      iconPreUrl: `https://fast-escrow-h5.staging.lenteradana.co.id`,
    };
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {
      iconPreUrl: `https://cdn.scredit.sg/${BIZ_COUNTRY}/fastescrow-h5/live`,
    };
  }

  // stable
  if (REACT_APP_ENV === 'stable') {
    return {
      iconPreUrl: `https://fastescrow-h5.test-stable.lenteradana.co.id`,
    };
  }

  // test, uat
  return {
    iconPreUrl: `https://fast-escrow-h5.${REACT_APP_ENV}.lenteradana.co.id`,
  };
})();

const requestHeaderConfig = (() => {
  switch (REACT_APP_ENV) {
    case 'mock':
    case 'dev':
    case 'stable':
    case 'test':
    case 'reg':
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTEyNzU0NTE2LCJqdGkiOiI0MDEzM2I1NC0wOWQ0LTRkMWEtYTIxYy1lYzI5Njg1NzdhNGMiLCJpYXQiOjE1OTczOTQ1MTYsImlzcyI6ImZhc3QtZXNjcm93LWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.emAWYNb7pGUBc9m1ya8RdaFZAqoQnnCyz6diswub0PkLcM-NMMqJKKde_2LJ0UDJJD89VusTzZFEX0BGmUYMCg',
      };
    case 'test1': // 映射成staging
    case 'uat':
    case 'staging':
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTEyNzU0NTkzLCJqdGkiOiI1ZTJmZGJlYS1iYjUxLTQ3NTUtOTgwNC1iMWIyMTkyY2FiMTMiLCJpYXQiOjE1OTczOTQ1OTMsImlzcyI6ImZhc3QtZXNjcm93LWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.hmh-FMjJlFsc-BMpH89hpRD0PO55WNKbwz8QmgQch4i2P8b2oW0mRN_rDC-kR-uKAPVqWnwH70qfiRbDy9EMug',
      };
    case 'live':
    default:
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTEyNzU0NjMwLCJqdGkiOiI4MDY0YmJmNC00YjY5LTQ3YmItYTA4My0zNWQzODcwMmFmYzMiLCJpYXQiOjE1OTczOTQ2MzAsImlzcyI6ImZhc3QtZXNjcm93LWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.u7wH7iKNqqO92LYNZXsukxx7ltV2G3ns05027Yv8flMg6NUaqA0SFa23RSHz4y3VHbp2PT6CUvH8iRmsXxMT3w',
      };
  }
})();

const dependDomain = (() => {
  switch (REACT_APP_ENV) {
    case 'mock':
    case 'dev':
      return {
        ucDomain: 'https://uc-h5-env3.test.lenteradana.co.id',
        financeDomain: 'https://finance-env3.test.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5-env3.test.lenteradana.co.id',
      };
    // TODO: need to update
    case 'stable':
      return {
        ucDomain: 'https://uc-h5.test-stable.lenteradana.co.id',
        financeDomain: 'https://finance.test-stable.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5.test-stable.lenteradana.co.id',
      };
    case 'test':
      return {
        ucDomain: 'https://uc-h5.test.lenteradana.co.id',
        financeDomain: 'https://finance.test.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5.test.lenteradana.co.id',
      };
    case 'test1':
      return {
        ucDomain: 'https://uc-h5.staging.lenteradana.co.id',
        financeDomain: 'https://finance.staging.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5.staging.lenteradana.co.id',
      };
    case 'reg':
      return {
        ucDomain: 'https://uc-h5-reg.test.lenteradana.co.id',
        financeDomain: 'https://finance-reg.test.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5-reg.test.lenteradana.co.id',
      };
    case 'uat':
      return {
        ucDomain: 'https://uc-h5.uat.lenteradana.co.id',
        financeDomain: 'https://finance.uat.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5.uat.lenteradana.co.id',
      };
    case 'live':
    default:
      return {
        ucDomain: 'https://uc-h5.lenteradana.co.id',
        financeDomain: 'https://finance.shopeekredit.co.id',
        cashloanDomain: 'https://spinjam-h5.lenteradana.co.id',
      };
  }
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'id',
  fastEscrowVersion: 'fastescrow_v1.0.0.100',
  ...subConfig,
  ...envConfig,
  ...requestHeaderConfig,
  ...dependDomain,
};

export default config;
